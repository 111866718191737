import classes from "./ClientDetailSkeleton.module.less";
import ClientNavigationSkeleton from "JS/React/Components/ClientNavigationSkeleton";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import GeneralTableSkeleton from "JS/React/Scenes/Integrations/GeneralTableSkeleton";
import OrganizationTableSkeleton from "JS/React/Scenes/Organization/OrganizationTableSkeleton";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import type { OrganizationType } from "JS/Shared";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type ClientDetailSkeletonClassKeys = "spacing" | "cardsDiv";
export interface ClientDetailSkeletonProps
    extends BaseComponentProps<ClientDetailSkeletonClassKeys> {
    type?: OrganizationType;
}

const useStyles = makeStyles<ClientDetailSkeletonClassKeys>(classes);

export const ClientDetailSkeleton = (props: ClientDetailSkeletonProps) => {
    const classes = useStyles(props);
    const location = useLocation().pathname;
    const [isBrand, setIsBrand] = useState<boolean>(false);

    useEffect(() => {
        if (
            location.includes("/portal/agencies/") &&
            location.includes("/brands")
        ) {
            setIsBrand(true);
        } else {
            setIsBrand(false);
        }
    }, [location]);

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <ClientNavigationSkeleton />
            </div>
            <Row style={{ margin: "50px 0px" }}>
                <Col span={4}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={4} style={{ margin: "0px 10px" }}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={4}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
            </Row>
            {/* <Tabs
                activeKey={currentLocation.pathname}
                onChange={(key) => {
                    console.log("keyyyyyyyyyyyyyy", key);
                    navigate(key);
                }}
            >
                <TabPane tab="Users" key={usersLocation.pathname}>
                    <div className={classes.spacing}>
                        <ClientTableSkeleton />
                    </div>
                </TabPane>

                <TabPane tab="Integrations" key={integrationsLocation.pathname}>
                    <div className={classes.spacing}>
                        <ClientTableSkeleton />
                    </div>
                </TabPane>
                {type === OrganizationType.Agency && (
                    <TabPane tab="Brands" key={brandLocation.pathname}>
                        <Row className={classes.cardsDiv} gutter={[25, 0]}>
                            {[1, 2, 3, 4, 5, 6].map((brand) => (
                                <Col span={24} xl={12} key={brand}>
                                    <OrganizationCardSkeleton />
                                </Col>
                            ))}
                        </Row>
                    </TabPane>
                )}
                {type === OrganizationType.Brand && (
                    <TabPane tab="Sub-brands" key={subBrandsLocation.pathname}>
                        <div className={classes.spacing}>
                            <ClientTableSkeleton />
                        </div>
                    </TabPane>
                )}
            </Tabs> */}

            <div className={classes.spacing}>
                <GeneralTableSkeleton />
            </div>
        </>
    );
};
