import { SkeletonProvider } from "JS/React/Container/Skeleton";
import AppLayoutSkeleton from "JS/React/Scenes/AppLayoutSkeleton";
import LoginLayoutSkeleton from "JS/React/Scenes/Login/LoginLayoutSkeleton";
import React, { Suspense } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

const SkeletonRoot = () => {
    const location = useLocation();
    const pathname = ["/login", "/invite"];
    const checkPath = () => {
        let flag = false;
        pathname.forEach((x) => {
            if (location.pathname.includes(x)) {
                flag = true;
            }
        });
        return flag;
    };
    return (
        <SkeletonProvider
            showSkeleton
            renderSkeleton={() => {
                return checkPath() ? (
                    <LoginLayoutSkeleton />
                ) : (
                    <AppLayoutSkeleton />
                );
            }}
        />
    );
};

const Root = React.lazy(() => import("./Main"));

export const App = (props) => {
    // return (
    //     <Select
    //         size="middle"
    //         value={null}
    //         placeholder="Bulk action"
    //         onChange={(e) => {
    //             console.log("e", e);
    //         }}
    //         allowClear={true}
    //         disabled={false}
    //     >
    //         <Select.Option value={"1"}>Active users</Select.Option>
    //         <Select.Option value={"2"}>Disable users</Select.Option>
    //     </Select>
    // );
    return (
        <div>
            <Suspense
                fallback={
                    <Router>
                        <SkeletonRoot />
                    </Router>
                }
            >
                <Root />
            </Suspense>
        </div>
    );
};
