import type {
    ResourceIdentifier,
    Role,
    User,
} from "@umbrelladm/nomad-packages.lib";
import { toOrganizations } from "@umbrelladm/nomad-packages.lib";

export * from "@umbrelladm/nomad-packages.lib";

//todo remove when available via bit
export const userBelongsToOrg = (
    user: User,
    orgIdentifier: ResourceIdentifier
): boolean => {
    const orgs = toOrganizations({
        clients: [user.client].filter((x) => x),
        agencies: [user.agency].filter((x) => x),
    });
    return !!orgs.find((x) => x.identifier === orgIdentifier);
};

//todo remove when available via bit
export const getUserParentOrganization = (
    user: Pick<User, "agency" | "client">
) => {
    const orgs = toOrganizations({
        clients: [user.client].filter((x) => x),
        agencies: [user.agency].filter((x) => x),
    });

    return orgs[0] ? orgs[0] : null;
};
