import classes from "./ClientDetailCardSkeleton.module.less";
import { OrganizationCardSkeleton } from "JS/React/Components/Cards/OrganizationCardSkeleton";
import ClientNavigationSkeleton from "JS/React/Components/ClientNavigationSkeleton";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import type { OrganizationType } from "JS/Shared";
import { Col, Row } from "antd";
import React from "react";

type ClientDetailCardSkeletonClassKeys = "spacing" | "cardsDiv";
export interface ClientDetailCardSkeletonProps
    extends BaseComponentProps<ClientDetailCardSkeletonClassKeys> {
    type?: OrganizationType;
}

const useStyles = makeStyles<ClientDetailCardSkeletonClassKeys>(classes);

export const ClientDetailCardSkeleton = (
    props: ClientDetailCardSkeletonProps
) => {
    const classes = useStyles(props);
    // const location = useLocation().pathname;
    // const [isBrand, setIsBrand] = useState<boolean>(false);

    // useEffect(() => {
    //     if (
    //         location.includes("/portal/agencies/") &&
    //         location.includes("/brands")
    //     ) {
    //         setIsBrand(true);
    //     } else {
    //         setIsBrand(false);
    //     }
    // }, [location]);

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <ClientNavigationSkeleton />
            </div>
            <Row style={{ margin: "50px 0px" }}>
                <Col span={2}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={2} style={{ margin: "0px 10px" }}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
                <Col span={2}>
                    <Skeletons active variant="navigationInput1" />
                </Col>
            </Row>

            <div className={classes.spacing}>
                <Row className={classes.cardsDiv} gutter={[25, 0]}>
                    {[1, 2, 3, 4, 5, 6].map((brand) => (
                        <Col span={24} xl={12} key={brand}>
                            <OrganizationCardSkeleton />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};
