import classes from "./AppLayoutSkeleton.module.less";
import { Skeletons } from "JS/React/Components/Skeleton/Skeletons";
import { AppSidebarSkeleton } from "JS/React/Scenes/AppSidebarSkeleton";
import { CreateIntegrationSkeleton } from "JS/React/Scenes/Integrations/CreateIntegration/CreateIntegrationSkeleton";
import { ClientDetailCardSkeleton } from "JS/React/Scenes/Organization/ClientDetailCardSkeleton";
import { ClientDetailSkeleton } from "JS/React/Scenes/Organization/ClientDetailSkeleton";
import OrganizationsSkeleton from "JS/React/Scenes/Organization/OrganizationsSkeleton";
import { AnalyticReportsSkeleton } from "JS/React/Scenes/Reports/AnalyticReportsSkeleton";
import { CreateReportSkeleton } from "JS/React/Scenes/Reports/CreateReportSkeleton";
import { DisplayReportsSkeleton } from "JS/React/Scenes/Reports/DisplayReportsSkeleton";
import AccountSettingSkeleton from "JS/React/Scenes/Users/AccountSettingSkeleton";
import UsersSkeleton from "JS/React/Scenes/Users/UsersSkeleton";
import type { BaseComponentProps } from "JS/React/StyleHelper";
import { makeStyles } from "JS/React/StyleHelper";
import { OrganizationType } from "JS/Shared";
import type { LayoutProps } from "antd";
import { Col, Layout, Row } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import clsx from "clsx";
import React from "react";
import { Route, Routes } from "react-router-dom";

type AppLayoutClasses = "root" | "header" | "content" | "layout";

export const enum ComponentType {
    SETTING = "Setting",
    USERS = "Users",
    INTEGRATIONS = "Integrations",
    CLIENTS = "Clients",
    CLIENT_DETAIL = "Client_Detail",
}
const useStyles = makeStyles<AppLayoutClasses>(classes);

export interface AppLayoutSkeletonProps
    extends LayoutProps,
        BaseComponentProps<AppLayoutClasses> {}
function AppLayoutSkeleton(props: AppLayoutSkeletonProps) {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    return (
        <Layout className={clsx(className, classes.root)} {...rest}>
            <Header className={clsx(classes.root, classes.header)}>
                <Row justify="space-between" style={{ width: "100%" }}>
                    <Col span={12}>
                        <Row style={{ width: "100%" }} justify="start">
                            <Col span={2}>
                                <Skeletons
                                    variant="avatar"
                                    style={{ verticalAlign: "middle" }}
                                    active
                                />
                            </Col>
                            <Col span={13}>
                                <Skeletons
                                    variant="cardInput2"
                                    style={{ verticalAlign: "middle" }}
                                    active
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row style={{ width: "100%" }} justify="end">
                            <Col span={13}>
                                <Skeletons
                                    variant="cardInput2"
                                    style={{ verticalAlign: "middle" }}
                                    active
                                />
                            </Col>
                            <Col span={2} offset={1}>
                                <Skeletons
                                    variant="avatar"
                                    style={{ verticalAlign: "middle" }}
                                    active
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>
            <Layout>
                <AppSidebarSkeleton />
                <div
                    className={clsx(
                        classes.root,
                        classes.content,
                        classes.layout
                    )}
                >
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Routes>
                            <Route
                                path={"/login"}
                                element={<OrganizationsSkeleton />}
                            />
                            <Route path="portal">
                                <Route
                                    path={"users"}
                                    element={<UsersSkeleton />}
                                />
                                <Route
                                    path={"reports"}
                                    element={<AnalyticReportsSkeleton />}
                                />
                                <Route
                                    path={"organizations"}
                                    element={<OrganizationsSkeleton />}
                                />
                                <Route
                                    path={"integrations"}
                                    element={<UsersSkeleton />}
                                />
                                <Route path={"integrations"}>
                                    <Route
                                        path={"create"}
                                        element={<CreateIntegrationSkeleton />}
                                    />
                                </Route>

                                <Route path={"account"}>
                                    <Route
                                        index={true as any}
                                        path={"settings"}
                                        element={<AccountSettingSkeleton />}
                                    />
                                </Route>

                                {/* Super admin agency */}
                                <Route path={"agencies"}>
                                    <Route
                                        path={":agencyId"}
                                        element={
                                            <ClientDetailSkeleton
                                                type={OrganizationType.Agency}
                                            />
                                        }
                                    >
                                        <Route
                                            path="brands"
                                            element={
                                                <ClientDetailSkeleton
                                                    type={
                                                        OrganizationType.Brand
                                                    }
                                                />
                                            }
                                        >
                                            <Route
                                                path={":brandId"}
                                                element={
                                                    <ClientDetailSkeleton
                                                        type={
                                                            OrganizationType.Brand
                                                        }
                                                    />
                                                }
                                            >
                                                <Route
                                                    path="sub-brands"
                                                    element={
                                                        <ClientDetailSkeleton
                                                            type={
                                                                OrganizationType.Brand
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="integrations"
                                                    element={
                                                        <ClientDetailSkeleton
                                                            type={
                                                                OrganizationType.Brand
                                                            }
                                                        />
                                                    }
                                                />
                                            </Route>
                                        </Route>
                                        <Route
                                            path="integrations"
                                            element={
                                                <ClientDetailSkeleton
                                                    type={
                                                        OrganizationType.Agency
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="users"
                                            element={
                                                <ClientDetailSkeleton
                                                    type={
                                                        OrganizationType.Agency
                                                    }
                                                />
                                            }
                                        />
                                    </Route>
                                </Route>
                                <Route path="brands">
                                    <Route
                                        index
                                        element={
                                            <ClientDetailCardSkeleton
                                                type={OrganizationType.Brand}
                                            />
                                        }
                                    />
                                    <Route
                                        path={":brandId"}
                                        element={
                                            <ClientDetailSkeleton
                                                type={OrganizationType.Brand}
                                            />
                                        }
                                    >
                                        <Route
                                            path="sub-brands"
                                            element={
                                                <ClientDetailSkeleton
                                                    type={
                                                        OrganizationType.Brand
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="integrations"
                                            element={
                                                <ClientDetailSkeleton
                                                    type={
                                                        OrganizationType.Brand
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="users"
                                            element={
                                                <ClientDetailSkeleton
                                                    type={
                                                        OrganizationType.Agency
                                                    }
                                                />
                                            }
                                        />
                                    </Route>
                                </Route>

                                <Route
                                    path={"sub-brands"}
                                    element={
                                        <ClientDetailSkeleton
                                            type={OrganizationType.Brand}
                                        />
                                    }
                                />

                                <Route
                                    path={"reports"}
                                    element={
                                        <ClientDetailSkeleton
                                            type={OrganizationType.Brand}
                                        />
                                    }
                                />
                                <Route path="reports">
                                    <Route
                                        path={"create"}
                                        element={<CreateReportSkeleton />}
                                    />
                                    <Route
                                        path={":reportId/display"}
                                        element={<DisplayReportsSkeleton />}
                                    />
                                </Route>
                            </Route>
                        </Routes>
                    </Content>
                </div>
            </Layout>
        </Layout>
    );
}

export default AppLayoutSkeleton;
